<template>
	<div class="notificacionFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.numdoc')" :value="getNumdocValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.estado')" :value="getEstadoValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.tipoman')" :value="getTipomanValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.notiforigen')" :value="getNotiforigenValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.agentedecnif')" :value="getAgentedecnifValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.agentedecnombre')" :value="getAgentedecnombreValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.numescent')" :value="getNumescentValue"></pui-field>
			</v-col>
			<v-col cols="3">
				<pui-field :label="$t('header.notificacion.numescsal')" :value="getNumescsalValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'notificacion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'notificacion'
		};
	},
	computed: {
		getNumdocValue() {
			return this.model && this.model.numdoc ? this.model.numdoc : '-';
		},
		getEstadoValue() {
			return this.model && this.model.estado ? this.model.estado : '-';
		},
		getTipomanValue() {
			return this.model && this.model.tipoman ? this.model.tipoman : '-';
		},
		getAgentedecnifValue() {
			return this.model && this.model.agentedecnif ? this.model.agentedecnif : '-';
		},
		getAgentedecnombreValue() {
			return this.model && this.model.agentedecnombre ? this.model.agentedecnombre : '-';
		},
		getNotiforigenValue() {
			return this.model && this.model.notiforigen ? this.model.notiforigen : '-';
		},
		getNumescentValue() {
			return this.model && this.model.numescent ? this.model.numescent : '-';
		},
		getNumescsalValue() {
			return this.model && this.model.numescsal ? this.model.numescsal : '-';
		}
	}
}
</script>
